import React, { useEffect, useState } from 'react'

const ScrollToTopButton = () => {
	const [showScroll, setShowScroll] = useState(false)

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 150) {
			setTimeout(() => {
				setShowScroll(true)
			}, 100)
		} else if (showScroll && window.pageYOffset <= 150) {
			setTimeout(() => {
				setShowScroll(false)
			}, 100)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', checkScrollTop)
		return function cleanup() {
			window.removeEventListener('scroll', checkScrollTop)
		}
	}, [showScroll])

	function scrollToTop() {
		window.scrollTo(0, 0)
	}

	return (
		<button
			onClick={scrollToTop}
			value="scroll to top"
			className={`${
				showScroll ? 'translate-x-0' : 'translate-x-28'
			} fixed right-[35px] bottom-[175px] z-50 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-100 px-3 py-1 shadow-md transition-all duration-200 ease-in-out hover:bg-gray-200`}
			aria-label="scroll to top"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-6 w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				strokeWidth={2}
			>
				<path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
			</svg>
		</button>
	)
}

export default ScrollToTopButton
