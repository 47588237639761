import React from 'react'

export default function MessageBanner() {
	return (
		<div className="w-full bg-black p-2">
			<p className="text-center text-xs font-normal text-white sm:text-sm lg:text-base">
				New orders will ship approximately 6-8 weeks from receipt of purchase order.
				{/* Please be aware that American Reading Company will be closed July 1st through July 9th, and will reopen
				July 10th. */}
			</p>
		</div>
	)
}
