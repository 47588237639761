import SupportFeedbackScreen from 'components/Support/SupportFeedbackScreen'
import SupportSuccessScreen from 'components/Support/SupportSuccessScreen'
import React, { useState } from 'react'
import ReactModal from 'react-modal'
import SupportCategoryScreen from './SupportCategoryScreen'

interface Props {
	isOpen: boolean
	handleClose: () => void
}

const SupportModal = ({ isOpen, handleClose }: Props) => {
	const [screen, setScreen] = useState<'category' | 'feedback' | 'success'>('category')

	const handleSetScreen = (screen: 'category' | 'feedback' | 'success') => {
		setScreen(screen)
	}

	return (
		<ReactModal
			className="support-modal fixed bottom-10 right-4 rounded-lg border border-slate-100 bg-white shadow-xl outline-none"
			overlayClassName="inset-0 fixed bg-transparent"
			isOpen={isOpen}
			onRequestClose={handleClose}
			onAfterClose={() => setScreen('category')}
			closeTimeoutMS={500}
			ariaHideApp={false}
		>
			<div className="relative h-48 w-80 p-5 sm:w-96">
				{screen === 'category' && (
					<SupportCategoryScreen handleClose={handleClose} handleSetScreen={handleSetScreen} />
				)}
				{screen === 'feedback' && (
					<SupportFeedbackScreen handleClose={handleClose} handleSetScreen={handleSetScreen} />
				)}
				{screen === 'success' && <SupportSuccessScreen />}
			</div>
			<button onClick={handleClose} className="absolute right-5 top-5 scale-x-125 transform font-semibold">
				X
			</button>
			{screen === 'feedback' && (
				<button onClick={() => setScreen('category')} className="absolute left-5 top-5">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
					</svg>
				</button>
			)}
		</ReactModal>
	)
}

export default SupportModal
