import Link from 'next/link'
import React, { useEffect, useState } from 'react'

interface Props {
	cartLength: number
}

export default function CartButton({ cartLength }: Props) {
	const [showCart, setShowCart] = useState(false)

	useEffect(() => {
		const checkPageOffset = () => {
			if (window.pageYOffset > 150) {
				setShowCart(true)
			} else {
				setShowCart(false)
			}
		}
		window.addEventListener('scroll', checkPageOffset)
		return function cleanup() {
			window.removeEventListener('scroll', checkPageOffset)
		}
	}, [])

	return (
		<Link href="/cart">
			<a>
				<button
					id="sticky-cart-button"
					tabIndex={-1}
					className={`fixed bottom-24 right-6 flex h-16 w-16 ${
						showCart ? 'translate-x-0' : 'translate-x-28'
					} items-center justify-center rounded-full bg-ab-100  p-4 transition-all duration-200 ease-in-out hover:bg-ab-200`}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6 text-white"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
						/>
					</svg>
					{cartLength > 0 && (
						<div className="absolute -top-2 right-0 flex items-center justify-center rounded-full border border-ab-100 bg-white px-2 text-sm font-medium text-ab-100">
							{cartLength}
						</div>
					)}
				</button>
			</a>
		</Link>
	)
}
