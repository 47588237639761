import React from 'react'

interface Props {
	size?: 'ss' | 'xs' | 'sm' | 'md' | 'lg'
}

export default function AnimatedCheckmark({ size = 'lg' }: Props) {
	function determinSize() {
		if (size === 'ss') return 'h-5 w-5'
		if (size === 'xs') return 'h-9 w-9'
		if (size === 'sm') return 'h-12 w-12'
		if (size === 'md') return 'h-24 w-24'
		if (size === 'lg') return 'h-36 w-36'
	}

	return (
		<svg
			className={`block ${determinSize()} animate-fill-green rounded-full stroke-white stroke-2`}
			style={{ strokeMiterlimit: 10, boxShadow: 'inset 0px 0px 0px #10b981' }}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 52 52"
		>
			<circle
				className="animate-stroke-short stroke-emerald-500 stroke-2"
				style={{ strokeDasharray: 166, strokeDashoffset: 166, strokeMiterlimit: 10 }}
				cx="26"
				cy="26"
				r="25"
				fill="none"
			/>
			<path
				className="origin-[50%_50%] animate-stroke-long"
				style={{ strokeDasharray: 48, strokeDashoffset: 48 }}
				fill="none"
				d="M14.1 27.2l7.1 7.2 16.7-16.8"
			/>
		</svg>
	)
}
