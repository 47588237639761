import router from 'next/router'
import React from 'react'
import FeedbackCategoryButton from './SupportCategoryButton'

interface Props {
	handleClose: () => void
	handleSetScreen: (screen: 'category' | 'feedback' | 'success') => void
}

const FeedbackCategoryScreen = ({ handleClose, handleSetScreen }: Props) => {
	function handleGetHelp() {
		handleClose()
		window.open('https://www.americanreading.com/customer-care/', '_blank')
	}

	function handleTutorial() {
		localStorage.removeItem('ladoeTourComplete')
		if (router.pathname === '/products/louisiana') window.location.reload()
		else router.push('/products/louisiana')
	}

	return (
		<>
			<span className="block text-center text-xl font-bold">How can we help?</span>
			<div className="mt-3 flex w-full justify-center">
				{router.pathname === '/products/louisiana' && (
					<FeedbackCategoryButton
						onClick={handleTutorial}
						icon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-10 w-10 text-ar-100"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
									clipRule="evenodd"
								/>
							</svg>
						}
						text="Tutorial"
					/>
				)}
				<FeedbackCategoryButton
					onClick={handleGetHelp}
					icon={
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-10 w-10 text-ab-100"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fillRule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
								clipRule="evenodd"
							/>
						</svg>
					}
					text="Help"
				/>
				<FeedbackCategoryButton
					onClick={() => handleSetScreen('feedback')}
					icon={
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-10 w-10 text-emerald-500"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fillRule="evenodd"
								d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
								clipRule="evenodd"
							/>
						</svg>
					}
					text="Feedback"
				/>
			</div>
		</>
	)
}

export default FeedbackCategoryScreen
