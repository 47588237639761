import AnimatedCheckmark from 'components/AnimatedCheckmark'
import React from 'react'

const SupportSuccessScreen = () => {
	return (
		<>
			<div className="mb-2 flex justify-center">
				<AnimatedCheckmark size="md" />
			</div>
			<span className="block text-center text-lg font-medium">Your feedback has been received.</span>
		</>
	)
}

export default SupportSuccessScreen
