import SupportModal from 'components/Support/SupportModal'
import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const SupportButton = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [showButton, setShowButton] = useState(true)
	const [shrink, setSrink] = useState(false)

	const checkScrollTop = () => {
		if (!shrink && window.pageYOffset > 150) {
			setSrink(true)
		} else if (shrink && window.pageYOffset <= 150) {
			setSrink(false)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', checkScrollTop)
		return function cleanup() {
			window.removeEventListener('scroll', checkScrollTop)
		}
	}, [shrink])

	return (
		<>
			<div className={twMerge('fixed bottom-10 transition-all duration-200', shrink ? 'right-9' : 'right-6')}>
				<button
					onClick={() => setIsModalOpen(true)}
					className={twMerge(
						'items-center justify-center rounded-full bg-core-r text-white transition-all duration-200 hover:bg-ar-200',
						showButton ? 'flex' : 'hidden',
						shrink ? 'h-10 w-10' : 'h-10 w-32'
					)}
				>
					<div className={twMerge('flex items-center gap-2', shrink ? 'translate-x-9' : 'translate-x-0')}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={twMerge('transition-all duration-200', shrink ? 'h-5 w-5' : 'h-6 w-6')}
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fillRule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
								clipRule="evenodd"
							/>
						</svg>
						<span
							className={twMerge(
								'text-lg transition-opacity delay-100',
								shrink ? 'opacity-0' : 'opacity-100'
							)}
						>
							Support
						</span>
					</div>
				</button>
				<button
					className={twMerge(
						'absolute -top-1 -right-1 h-5 w-5 items-center justify-center rounded-full border bg-white text-sm hover:bg-slate-200',
						showButton ? 'flex' : 'hidden'
					)}
					onClick={() => setShowButton(false)}
				>
					x
				</button>
			</div>
			<SupportModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} />
		</>
	)
}

export default SupportButton
