import { api } from 'clients/axios'
import { FeedbackCollection } from 'clients/firebase-client'
import Button from 'components/Button'
import { ProfileContext } from 'context/Profile'
import { addDoc, Timestamp } from 'firebase/firestore'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'

interface Props {
	handleClose: () => void
	handleSetScreen: (screen: 'category' | 'feedback' | 'success') => void
}

const SupportFeedbackScreen = ({ handleSetScreen }: Props) => {
	const router = useRouter()
	const { profile } = useContext(ProfileContext)
	const [feedback, setFeedback] = useState('')
	const [loading, setLoading] = useState(false)

	async function handleSubmit() {
		try {
			setLoading(true)
			await addDoc(FeedbackCollection, {
				createdAt: Timestamp.now(),
				feedback,
				submittedBy: profile ? `${profile?.firstName} ${profile?.lastName}` : 'Anonymous'
			})
			await api.post('/api/v1/email/feedback-notification', {
				feedback,
				submittedBy: profile ? `${profile?.firstName} ${profile?.lastName}` : 'Anonymous'
			})
			setTimeout(() => {
				LogRocket.track('Feedback Submitted')
				setLoading(false)
				handleSetScreen('success')
			}, 1500)
		} catch (error: any) {
			Sentry.captureException(error, {
				tags: {
					role: profile?.role || 'Customer'
				},
				extra: {
					action: 'Feedback Submit',
					pageName: router.pathname
				}
			})
			LogRocket.captureException(error, {
				tags: {
					role: profile?.role || 'Customer'
				},
				extra: {
					action: 'Feedback Submit',
					pageName: router.pathname
				}
			})
			console.log(error)
			setLoading(false)
		}
	}

	return (
		<>
			<span className="block text-center text-xl font-bold">What&apos;s on your mind?</span>
			<div className="mt-3">
				<textarea
					value={feedback}
					onChange={(e) => setFeedback(e.target.value)}
					placeholder="How can we improve your experience?"
					className="h-auto w-full resize-none rounded border p-3 placeholder:text-slate-400 focus:border-ab-100 focus:outline-none"
				/>
				<div className="mt-2 flex justify-center">
					<Button uppercase={false} size="sm" loading={loading} onClick={handleSubmit}>
						Submit
					</Button>
				</div>
			</div>
		</>
	)
}

export default SupportFeedbackScreen
