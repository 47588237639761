import LoadingOverlay from 'components/LoadingOverlay'
import { QuoteContext } from 'context/Quote'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'

import { CartContext } from '../context/Cart'
import { ProfileContext } from '../context/Profile'
import MessageBanner from './MessageBanner'
import MainNav from './Navigation/MainNav'
import ProfileMenu from './ProfileMenu'
import Head from 'next/head'
import Image from 'next/image'
import CartButton from 'Layout/CartButton'
import Footer from 'Layout/Footer'
import { Toaster } from 'react-hot-toast'
import SupportButton from 'components/Support/SupportButton'
import ScrollToTopButton from 'Layout/ScrollToTopButton'
import EmailContactToast from 'Layout/EmailContactToast'

interface Props {
	children?: any
	full?: boolean
	gray?: boolean
	pageTitle?: string
	noCart?: boolean
}

export default function Layout({ children, full, gray, pageTitle, noCart }: Props) {
	const { cart, getCartCount, clearCart } = useContext(CartContext)
	const { editMode, setEditMode, setQuote, quote } = useContext(QuoteContext)

	const { profile } = useContext(ProfileContext)
	const router = useRouter()

	const [loading, setLoading] = useState(false)

	// Cancel the edit quote mode clear the cart and refresh the page
	function handleCancelEdit() {
		setLoading(true)
		setTimeout(() => {
			setEditMode(false)
			clearCart()
			router.push(`/quotes/${quote?.id}`)
			setQuote(null)
			setLoading(false)
		}, 1000)
	}

	return (
		<div className="flex min-h-screen flex-col">
			<Head>
				<title>{pageTitle}</title>
			</Head>
			<header>
				<MessageBanner />
				<Toaster />
				<LoadingOverlay dark loading={loading} />
				<div className="nav-gradient relative z-20 w-full shadow">
					<div className="container px-4">
						<div className="flex items-center justify-between py-5">
							<div className="flex items-center gap-8">
								<a href="https://www.americanreading.com" className="flex items-center gap-8">
									<div className="-mb-10 bg-ar-100 px-2 pt-2">
										<Image
											src="/images/arc-logo-white-tagline.svg"
											height={64}
											width={144}
											alt="arc logo"
										/>
									</div>
								</a>
								<MainNav />
							</div>
							<div className="flex items-center gap-5">
								<Link href="/cart" passHref>
									<a className="relative">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-7 w-7 text-white"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth={1.5}
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
											/>
										</svg>
										{cart.length > 0 && (
											<div className="absolute -top-2 -right-3 flex h-5 w-5 items-center justify-center rounded-full bg-ab-100 text-[10px] font-medium text-white">
												{getCartCount()}
											</div>
										)}
									</a>
								</Link>

								{profile ? (
									<ProfileMenu />
								) : (
									<div className="flex items-center gap-2 font-normal tracking-wider">
										<Link href="/login" passHref>
											<a className="text-white">Log In</a>
										</Link>
										<span className="h-4 w-px bg-white" aria-hidden="true" />
										<Link href="/signup" passHref>
											<a className="text-white">Register</a>
										</Link>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{editMode && (
					<div className="w-full bg-red-500 py-1 pt-6 md:pt-1">
						<p className="text-center font-medium text-white">
							You are currently editing quote #{quote?.quoteNumber}. To cancel editing{' '}
							<button onClick={handleCancelEdit} className="font-medium underline">
								click here
							</button>
						</p>
					</div>
				)}
			</header>
			<main className={`${gray ? 'bg-slate-50' : 'bg-white'}`}>
				<div className={`${full ? 'w-full' : 'container'}`}>{children}</div>
				<SupportButton />
				<ScrollToTopButton />
				<EmailContactToast />
				{!noCart && <CartButton cartLength={getCartCount()} />}
			</main>
			<Footer gray={gray} />
		</div>
	)
}
