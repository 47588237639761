import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Link from 'next/link'
import { signOut } from 'firebase/auth'
import { AuthContext } from 'context/Auth'
import { auth } from 'clients/firebase-client'
import { CartContext } from 'context/Cart'
import { ProfileContext } from 'context/Profile'
import Image from 'next/image'
import { releaseVersion } from '../constants'

export default function ProfileMenu() {
	const { isCustomer, isEmployee, isAdmin } = useContext(AuthContext)
	const { profile } = useContext(ProfileContext)
	const { clearCart } = useContext(CartContext)

	const menuItemStyles = 'px-4 py-3 hover:bg-ab-50 flex items-center gap-2'
	const menuItemLinkStyles = 'w-full inline-block'

	async function handleLogout() {
		await signOut(auth)
		clearCart()
		window.location.href = '/'
	}

	// TODO: Remove conditionals for CCSS link when ready to deploy

	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<Menu.Button className="inline-flex w-full justify-center rounded-md py-2 pl-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
					{profile?.photo ? (
						<Image
							src={profile.photo}
							alt={`${profile.firstName} ${profile.lastName} headshot`}
							height={40}
							width={40}
							className="rounded-full"
						/>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-7 w-7 text-white"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					)}
				</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-[99] mt-3 w-56 origin-top-right rounded-md bg-white text-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<Menu.Item as="div" className={`${menuItemStyles} hover:rounded-t-md md:hidden`}>
						<Link href="/products/standard">
							<a className={menuItemLinkStyles}>CCSS Products</a>
						</Link>
					</Menu.Item>
					<Menu.Item as="div" className={`${menuItemStyles} md:hidden`}>
						<Link href="/products/louisiana">
							<a className={menuItemLinkStyles}>Louisiana Products</a>
						</Link>
					</Menu.Item>
					<div className="block px-4 md:hidden">
						<div className="mb-1 w-full border-b pt-1" />
					</div>
					<Menu.Item as="div">
						<Link href="/profile">
							<a className={`hover:rounded-none md:hover:rounded-t-md ${menuItemStyles}`}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-4 w-4 text-ab-100"
									fill="#EDF2FF"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
									/>
								</svg>
								Profile
							</a>
						</Link>
					</Menu.Item>
					{isCustomer && (
						<Menu.Item as="div">
							<Link href="/my-quotes">
								<a className={menuItemStyles}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 text-ab-100"
										fill="#EDF2FF"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
										/>
									</svg>
									My Quotes
								</a>
							</Link>
						</Menu.Item>
					)}
					{isEmployee && (
						<Menu.Item as="div">
							<Link href="/admin/quotes">
								<a className={menuItemStyles}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 text-ab-100"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
										/>
									</svg>
									All Quotes
								</a>
							</Link>
						</Menu.Item>
					)}
					{isEmployee && (
						<Menu.Item as="div">
							<Link href="/admin/users">
								<a className={menuItemStyles}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 text-ab-100"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
										/>
									</svg>
									Users
								</a>
							</Link>
						</Menu.Item>
					)}
					{isEmployee && (
						<Menu.Item as="div">
							<Link href="/admin/reports/quotes">
								<a className={menuItemStyles}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 text-ab-100"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
										/>
									</svg>
									Reports
								</a>
							</Link>
						</Menu.Item>
					)}
					{isAdmin && (
						<Menu.Item as="div">
							<Link href="/admin/new-products">
								<a className={menuItemStyles}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 text-ab-100"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
										/>
									</svg>
									New Products
								</a>
							</Link>
						</Menu.Item>
					)}
					{isAdmin && (
						<Menu.Item as="div">
							<Link href="/admin/price-updates">
								<a className={menuItemStyles}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5 text-ab-100"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={1.5}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
										/>
									</svg>
									Price Updates
								</a>
							</Link>
						</Menu.Item>
					)}
					<Menu.Item as="div">
						<button
							onClick={() => handleLogout()}
							className={`${menuItemStyles} w-full text-left font-light`}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="h-4 w-4 text-ab-100"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
								/>
							</svg>
							Logout
						</button>
					</Menu.Item>
					{!isCustomer && (
						<div className="px-4 py-2">
							<Link href="/changelog">
								<a className="text-sm text-slate-400">v{releaseVersion}</a>
							</Link>
						</div>
					)}
				</Menu.Items>
			</Transition>
		</Menu>
	)
}
