import Link from 'next/link'

export default function MainNav() {
	return (
		<div className="hidden gap-8 md:flex">
			<Link href="/products/standard">
				<a className="font-medium text-white hover:underline hover:underline-offset-2">CCSS Products</a>
			</Link>
			<Link href="/products/louisiana">
				<a className="font-medium text-white hover:underline hover:underline-offset-2">Louisiana Products</a>
			</Link>
		</div>
	)
}
