import Button from 'components/Button'
import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export default function EmailContactToast() {
	let showModal = 'false'
	let email = ''
	if (typeof window !== 'undefined') {
		const params = new URLSearchParams(location.search)
		showModal = params.get('showAMContactToast') || 'false'
		email = params.get('email') || 'contact@americanreading.com'
	}
	const [show, setShow] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')

	useEffect(() => {
		setShow(showModal === 'true' ? true : false)
		setEmailAddress(email)
	}, [showModal, email])

	return (
		<div
			className={twMerge(
				'fixed top-8 right-8 z-50 overflow-hidden rounded-lg bg-white shadow transition-all',
				show ? 'translate-x-0' : 'translate-x-[450px]'
			)}
		>
			<div className="relative p-6">
				<div className="mb-3 mt-4">
					<span className="block text-center font-semibold">Connect With Your Account Manager</span>
				</div>
				<a href={`mailto:${emailAddress}`}>
					<Button full className="flex items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="mr-2 h-4 w-4"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
							/>
						</svg>
						Schedule a Demo Today
					</Button>
				</a>
				<button onClick={() => setShow(false)} className="absolute top-2 right-2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="h-6 w-6 rounded p-1 text-gray-800 hover:bg-gray-100"
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
				</button>
			</div>
			<div className="arc-gradient h-[6px] w-full"></div>
		</div>
	)
}
