import React, { SVGProps } from 'react'

interface Props {
	icon: SVGProps<SVGSVGElement>
	text: string
	onClick: () => void
}

const SupportCategoryButton = ({ icon, text, onClick }: Props) => {
	return (
		<div className="w-1/3 px-1">
			<button
				onClick={onClick}
				className="flex w-full flex-col items-center rounded-lg bg-slate-100 p-4 font-semibold hover:bg-slate-200"
			>
				{icon} {text}
			</button>
		</div>
	)
}

export default SupportCategoryButton
