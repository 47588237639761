import LoadingIcon from 'components/Button/LoadingIcon'
import React from 'react'

interface Props {
	/**
	 * @description
	 * If set to true, button will render with a solid background.
	 * If false, button will render with a white background and border.
	 *
	 * Set to `true` by default.
	 * @example
	 * <Button solid={false} />
	 */
	solid?: boolean
	/**
	 * @description
	 * If set to true, button will render uppercase text.
	 * If false, button will render with whatever case is passed to children.
	 *
	 * Set to `true` by default.
	 * @example
	 * <Button uppercase={false} />
	 */
	uppercase?: boolean
	/**
	 * @description
	 * Determins the size of the rendered button.
	 *
	 * Can be one of `sm` or `lg`
	 *
	 * Set to `lg` by default.
	 * @example
	 * <Button size="sm" />
	 */
	size?: 'sm' | 'lg'
	/**
	 * @description
	 * If set to true, button will render the full width of the parent container.
	 * If set to false, button will render the max width of the content
	 *
	 * Set to `false` by default.
	 * @example
	 * <Button full />
	 * */
	full?: boolean
	color?: 'primary' | 'secondary' | 'danger'
	loading?: boolean
	[props: string]: any
}

export default function Button({
	solid = true,
	uppercase = true,
	color = 'primary',
	onClick,
	size = 'lg',
	full,
	loading,
	...props
}: Props) {
	function setSolidColorClasses(color: 'primary' | 'secondary' | 'danger') {
		if (color === 'primary')
			return 'border-ab-100 bg-ab-100 text-white hover:bg-ab-200 disabled:border-slate-300 disabled:bg-slate-300'
		if (color === 'secondary')
			return 'border-orange-100 bg-orange-500 text-white hover:bg-orange-600 disabled:border-orange-300 disabled:bg-orange-300'
		if (color === 'danger')
			return 'border-red-600 bg-red-600 text-white hover:bg-red-700 disabled:border-red-300 disabled:bg-red-300'
	}

	function setOutlineColorClasses(color: 'primary' | 'secondary' | 'danger') {
		if (color === 'primary')
			return 'border-ab-100 bg-white text-ab-100 hover:bg-ab-100 hover:border-ab-100 hover:text-white disabled:bg-slate-300 disabled:border-slate-300 disabled:text-slate-400'
		if (color === 'secondary')
			return 'border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white disabled:bg-slate-300'
		if (color === 'danger')
			return 'border-red-600 bg-white text-red-600 hover:bg-red-600 hover:text-white disabled:bg-slate-300'
	}

	return (
		<button
			{...props}
			data-testid="standard-button"
			onClick={onClick}
			disabled={props.disabled || loading}
			className={`flex w-full items-center justify-center rounded border text-sm tracking-wide disabled:cursor-not-allowed
			${size === 'sm' ? 'px-4 py-1' : 'px-10 py-3'}
			${full ? 'w-full' : 'max-w-max'}
            ${uppercase ? 'uppercase' : 'normal-case'}
            ${solid ? setSolidColorClasses(color) : setOutlineColorClasses(color)}`}
		>
			{loading ? <LoadingIcon color={color} /> : props.children}
		</button>
	)
}
